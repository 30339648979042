import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MAIN_ROUTE } from '@constants/index.constant';
import { AppGuard } from './core/guards/app/app.guard';
import { AuthGuard } from './core/guards/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: MAIN_ROUTE,
    pathMatch: 'full',
  },
  {
    path: 'auth',
    canActivate: [AuthGuard],
    loadChildren: () => import('@modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    canActivate: [AppGuard],
    children: [
      {
        path: 'benefits',
        loadChildren: () => import('@modules/benefits/benefits.module').then((m) => m.BenefitsModule),
        data: { breadcrumb: { skip: true } },
      },
      {
        path: 'benefit',
        loadChildren: () => import('@modules/benefit/benefit.module').then((m) => m.BenefitModule),
        data: { breadcrumb: { skip: true } },
      },
      {
        path: 'cash-advance',
        loadChildren: () => import('@modules/cash-advance/cash-advance.module').then((m) => m.CashAdvanceModule),
        data: { breadcrumb: { skip: true } },
      },
      {
        path: 'payadvance',
        loadChildren: () => import('@modules/payadvance/payadvance.module').then((m) => m.PayadvanceModule),
        data: { breadcrumb: { skip: true } },
      },
      {
        path: 'e2pay',
        loadChildren: () => import('@modules/e2pay/e2pay.module').then((m) => m.E2payModule),
        data: { breadcrumb: { skip: true } },
      },
      {
        path: 'rajabiller',
        loadChildren: () => import('@modules/rajabiller/rajabiller.module').then((m) => m.RajabillerModule),
        data: { breadcrumb: { skip: true } },
      },
      {
        path: 'generic',
        loadChildren: () => import('@modules/generic/generic.module').then((m) => m.GenericModule),
        data: { breadcrumb: { skip: true } },
      },
      {
        path: 'change-password',
        loadChildren: () =>
          import('@modules/auth/change-password/change-password.module').then((m) => m.ChangePasswordPageModule),
        data: { breadcrumb: 'Change Password' },
      },
      {
        path: 'transaction',
        loadChildren: () => import('@modules/transaction/transaction.module').then((m) => m.TransactionModule),
      },
      {
        path: 'sales',
        loadChildren: () => import('@modules/sales/sales.module').then((m) => m.SalesModule),
        data: { breadcrumb: 'Sales' },
      },
      {
        path: 'support',
        loadChildren: () => import('@modules/support/support.module').then((m) => m.SupportModule),
      },
      {
        path: 'legal',
        loadChildren: () => import('@modules/legal/legal.module').then((m) => m.LegalModule),
      },
      {
        path: 'marketing',
        loadChildren: () => import('./modules/marketing/marketing.module').then((m) => m.MarketingModule),
      },
      {
        path: 'digital-goods-balance',
        loadChildren: () => import('./modules/digital-goods-balance/digital-goods-balance-overview.module').then((m) => m.DigitalGoodsBalanceOverviewModule),
      },
      {
        path: 'self-funded',
        loadChildren: () => import('./modules/self-funded/self-funded.module').then((m) => m.SelfFundedModule),
      },
    ],
  },
  { path: '**', redirectTo: 'auth' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      // initialNavigation: 'enabledNonBlocking',
      paramsInheritanceStrategy: 'always',
      // relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
