import { IBaseMenu } from '@dto/index.dto';

export interface SidebarMenu {
  title: string;
  menus: ListMenu[];
}

interface ListMenu {
  label: string;
  child: IBaseMenu[];
}

export const SideMenu: IBaseMenu[] = [
  {
    permission: 'noAuth',
    subMenu: 'Dashboard Summary',
    sideType: 'dashboard',
    activeSubMenu: false,
    route: '/benefits/benefits-summary',
    icon: 'assets/icons/document.svg',
  },
  {
    permission: 'billing',
    subMenu: 'Management',
    sideType: 'dashboard',
    activeSubMenu: false,
    route: '/benefits/management',
    icon: 'assets/icons/document.svg',
  },
  {
    permission: 'company',
    subMenu: 'Company Management',
    sideType: 'company',
    activeSubMenu: false,
    route: '/generic/company-management',
    icon: 'assets/icons/sys-setting.svg',
  },
  {
    permission: 'userProfileApproval',
    subMenu: 'User Profile Approval',
    sideType: 'company',
    activeSubMenu: false,
    route: '/generic/user-profile-approval',
    icon: 'assets/icons/icon-employee-checklist.svg',
  },
  {
    permission: 'member',
    subMenu: 'List Member',
    sideType: 'listMember',
    activeSubMenu: false,
    route: '/generic/list-member',
    icon: 'assets/icons/icon-discipline.svg',
  },
  {
    permission: 'member',
    subMenu: 'Master Sales',
    sideType: 'listMember',
    activeSubMenu: false,
    route: '/generic/master-sales',
    icon: 'assets/icons/icon-training-record.svg',
  },
  {
    permission: 'overview',
    subMenu: 'Cashadvance Balance',
    sideType: 'overview',
    activeSubMenu: false,
    route: '/benefits/benefits-overview',
    icon: 'assets/icons/payment-type.svg',
  },
  {
    permission: 'overview',
    subMenu: 'Self Funded',
    sideType: 'overview',
    activeSubMenu: false,
    route: '/self-funded',
    icon: 'assets/icons/payment-type.svg',
  },
  {
    permission: 'overview',
    subMenu: 'Benefit Collections',
    sideType: 'overview',
    activeSubMenu: false,
    route: '/benefit/benefit-overview',
    icon: 'assets/icons/my-payslip.svg',
  },
  {
    permission: 'overview',
    subMenu: 'E2pay Overview',
    sideType: 'overview',
    activeSubMenu: false,
    route: '/e2pay/e2pay-overview',
    icon: 'assets/icons/activity-recording-report.svg',
  },
  {
    permission: 'overview',
    subMenu: 'RJB Overview',
    sideType: 'overview',
    activeSubMenu: false,
    route: '/rajabiller/rajabiller-overview',
    icon: 'assets/icons/task-manage.svg',
  },
  {
    permission: 'billing',
    subMenu: 'Billing Report',
    sideType: 'billing',
    activeSubMenu: false,
    route: '/benefits/billing-report',
    icon: 'assets/icons/task-manage.svg',
  },
  {
    permission: 'transactionHistory',
    subMenu: 'Cash Advance',
    sideType: 'transactionHistory',
    activeSubMenu: false,
    route: '/cash-advance/cash-advance-transaction-history',
    icon: 'assets/icons/payment-type.svg',
  },
  {
    permission: 'transactionHistory',
    subMenu: 'Topup and Bills',
    sideType: 'transactionHistory',
    activeSubMenu: false,
    route: '/benefits/benefits-transaction-history',
    icon: 'assets/icons/icon-cart.svg',
  },
  {
    permission: 'financial',
    subMenu: 'Billing Settlement',
    sideType: 'financialAffairs',
    activeSubMenu: false,
    route: '/transaction/billing-settlement',
    icon: 'assets/icons/icon-letters.svg',
  },
  {
    permission: 'financial',
    subMenu: 'Pending Payment',
    sideType: 'financialAffairs',
    activeSubMenu: false,
    route: '/transaction/pending-payment',
    icon: 'assets/icons/icon-end-time.svg',
  },
  {
    permission: 'financial',
    subMenu: 'Payout',
    sideType: 'financialAffairs',
    activeSubMenu: false,
    route: '/transaction/payout',
    icon: 'assets/icons/new-calender.svg',
  },
  {
    permission: 'financial',
    subMenu: 'Payout Type',
    sideType: 'financialAffairs',
    activeSubMenu: false,
    route: '/transaction/payout-type',
    icon: 'assets/icons/icon-leave-balance.svg',
  },
  {
    permission: 'financial',
    subMenu: 'Repayment Bill',
    sideType: 'financialAffairs',
    activeSubMenu: false,
    route: '/transaction/repayment-bill',
    icon: 'assets/icons/icon-letters.svg',
  },
  {
    permission: 'financial',
    subMenu: 'Repayment History',
    sideType: 'financialAffairs',
    activeSubMenu: false,
    route: '/transaction/repayment-history',
    icon: 'assets/icons/icon-start-time.svg',
  },
  {
    permission: 'sales',
    subMenu: 'Monitoring',
    sideType: 'sales',
    activeSubMenu: false,
    route: '/sales/monitoring',
    icon: 'assets/icons/icon-concurrent-login.svg',
  },
  {
    permission: 'sales',
    subMenu: 'Greatday Leads',
    sideType: 'sales',
    activeSubMenu: false,
    route: '/sales/greatday-leads',
    icon: 'assets/icons/icon-subscription.svg',
  },
  {
    permission: 'marketing',
    subMenu: 'Banner Management',
    sideType: 'marketing',
    activeSubMenu: false,
    route: '/marketing/banner-management',
    icon: 'assets/icons/feeds.svg',
  },
  {
    permission: 'support',
    subMenu: 'Reset Pin',
    sideType: 'support',
    activeSubMenu: false,
    route: '/support/reset-pin',
    icon: 'assets/icons/icon-restrict-password.svg',
  },
  {
    permission: 'kyc',
    subMenu: 'User Verification',
    sideType: 'legal',
    activeSubMenu: false,
    route: '/legal/user-verification',
    icon: 'assets/icons/icon-contact.svg',
  },
  {
    permission: 'kyc',
    subMenu: 'User Verification Automatic',
    sideType: 'legal',
    activeSubMenu: false,
    route: '/legal/user-verification-automatic',
    icon: 'assets/icons/peers-review.svg',
  },
];
